<template>
  <div id="content">
    <div class="wrapper">
      <header>
        <h1>{{title}}</h1>
      </header>

      <div class="page-content" v-html="content"></div>
    </div>
  </div>
</template>

<script>
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

export default {
  name: 'FOPage',
  computed: {
    title: {
      get: function () {
        return this.$store.state.page.title
      }
    },
    content: {
      get: function () {
        return this.$store.state.page.content
      }
    }
  },
  created () {
    this.$emit('update:layout', DefaultLayout)
    document.title = config.title + ' - ' + this.title

    this.$store.dispatch('page/getData', this.$route.params.slug)
  }
}
</script>
